import { Params } from '@angular/router';

import { ApiParameter } from '@sulser-print/constants/parameters';
import { SortDirection } from '@sulser-print/constants/sort-direction';

function getBaseSharedIconUrl() {
	return `/assets/icons`;
}

export function getIonIconUrl(icon: string) {
	return `/assets/ion-icons/${icon}.svg`;
}

export function getMaterialIconUrl(icon: string) {
	return `${getBaseSharedIconUrl()}/mdi/${icon}.svg`;
}

export function getSharedIconUrl(icon: string) {
	return `${getBaseSharedIconUrl()}/${icon}.svg`;
}

export function getDesignSystemIconUrl(icon: string) {
	return `${getBaseSharedIconUrl()}/design-system/${icon}.svg`;
}

export function getAlphaSortIconFromParameters(parameters?: Params | null) {
	switch (parameters?.[ApiParameter.SORT]) {
		case 'name,asc': {
			return getSharedIconUrl('sort_a_z');
		}
		case 'name,desc': {
			return getSharedIconUrl('sort_z_a');
		}
		default: {
			return getMaterialIconUrl('sort-alpha');
		}
	}
}

export function getDateSortIconFromParameters(sortKey: string, parameters?: Params | null) {
	switch (parameters?.[ApiParameter.SORT]) {
		case `${sortKey},${SortDirection.ASC}`: {
			return getMaterialIconUrl('sort-date-ascending');
		}
		case `${sortKey},${SortDirection.DESC}`: {
			return getMaterialIconUrl('sort-date-descending');
		}
		default: {
			return getMaterialIconUrl('date-range');
		}
	}
}

export function getChevronSortIconFromSortValue(key: string, sortValue: string | undefined) {
	switch (sortValue) {
		case `${key},${SortDirection.ASC}`: {
			return getIonIconUrl('chevron-down-outline');
		}
		case `${key},${SortDirection.DESC}`: {
			return getIonIconUrl('chevron-up-outline');
		}
		default: {
			return getIonIconUrl('chevron-collapse-outline');
		}
	}
}

export function getChevronSortIconFromParameters(key: string, parameters?: Params | null) {
	switch (parameters?.[ApiParameter.SORT]) {
		case `${key},${SortDirection.ASC}`: {
			return getIonIconUrl('chevron-down-outline');
		}
		case `${key},${SortDirection.DESC}`: {
			return getIonIconUrl('chevron-up-outline');
		}
		default: {
			return getIonIconUrl('chevron-collapse-outline');
		}
	}
}
